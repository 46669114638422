import hotelmirandaback from "../assets/images/hotelmirandaback.png";
import hotelmirandadash from "../assets/images/hotelmirandadash.png";
import hotelmiranda from "../assets/images/hotelmiranda.png";
import myphotodash from "../assets/images/myphotodash.png";
import oxygenshop from "../assets/images/oxygenshop.png";
import kompi from "../assets/images/kompi.png";
import kompilanding from "../assets/images/kompilanding.png";

const projectImages = {
    images: [
        hotelmirandaback,
        hotelmirandadash,
        hotelmiranda,
        myphotodash,
        oxygenshop,
        kompi,
        kompilanding
    ]
}

export { projectImages };
